<template>
  <div class="create-content-main">
    <div class="setting-menu background-content-admin">
      <div class="row mx-3">
        <h2
          class="col-md-8 col-12 font-title font-weight-bold pl-0 my-auto header-font"
        >
          ボトムナビゲーション管理
        </h2>
        <div class="col-md-4 col-12 px-0">
          <b-button
            class="button-back-dashboard float-md-right"
            v-on:click="gotoDashboard()"
          >
            戻る
          </b-button>
        </div>
      </div>
      <div class="content-setting-header pt-4">
        <h4 class="mb-3 font-title-default label-title">
          どのページのボトムナビを変更するか選択してください。
        </h4>
        <div class="container">
          <div class="row justify-content-center mb-5">
            <div class="col-12 col-md-4 mx-auto">
              <b-form-select
                class="custome-text-select"
                id="select-cate"
                tag-placeholder="これを新しいヘッダーとして追加します"
                :options="listOptions"
                v-model="type"
                v-on:change="search()"
              >
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="container" v-if="check">
          <div>
            <div
              class="row my-2"
              v-for="(valueDefault, ind) in type == 0
                ? listDefault0
                : type == 1
                ? listDefault1
                : type == 2
                ? listDefault2
                : listDefault3"
              :key="ind"
            >
              <div class="col-md-1 my-auto"></div>
              <div class="col-10 col-md-6 my-auto">
                <input
                  class="w-100 custome-text-select"
                  id="select-cate"
                  v-model="valueDefault.text"
                  disabled
                  style="
                    height: calc(1.5em + 0.75rem + 2px);
                    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
                  "
                />
              </div>
              <div
                class="col-2 col-md-1 frame-icon my-auto pt-0 frame-icon-default"
              >
                <div v-if="valueDefault.icon" class="img-menu">
                  <img
                    width="100%"
                    height="100%"
                    :src="valueDefault.icon"
                    alt=""
                    style="object-fit: contain"
                  />
                </div>
              </div>
              <!-- <div class="col-5 col-md-2 btn-upload-icon btn-open"></div>
              <div class="col-5 col-md-2 btn-upload-icon btn-up"></div> -->
            </div>
          </div>
          <div
            class="row my-2"
            v-for="(value, index) in type == 0
              ? listBottom0
              : type == 1
              ? listBottom1
              : type == 2
              ? listBottom2
              : listBottom3"
            :key="index"
          >
            <div class="col-12 d-flex">
              <div class="col-1 col-md-1">
                <b-button
                  variant="dark"
                  class="rounded-circle custome-btn-sub"
                  style="border-radius: 50% !important"
                  v-on:click="subBottom(index, type)"
                  ><b-icon icon="dash" scale="2"
                /></b-button>
              </div>
              <div class="col-10 col-md-6">
                <b-form-select
                  class="w-100 custome-text-select"
                  id="select-cate"
                  v-model="value.page_title"
                  tag-placeholder="これを新しいヘッダーとして追加します"
                  :options="listPageOptions"
                  @change="changeType(value)"
                >
                </b-form-select>
              </div>
              <div class="col-1 cus-icon-hd">
                <div v-if="value.icon">
                  <div
                    v-if="isImage(value.icon)"
                    class="float-lg-right img-menu"
                  >
                    <img
                      width="100%"
                      height="100%"
                      :src="value.icon"
                      alt=""
                      style="object-fit: contain"
                    />
                  </div>
                  <b-icon
                    v-else
                    :icon="`${value.icon}`"
                    font-scale="3"
                    class="float-lg-right icon-menu"
                  />
                </div>
              </div>
              <div class="col-5 col-md-2 btn-upload-icon btn-open">
                <b-button
                  class="float-right"
                  variant="success"
                  v-b-modal.modal-add-icon
                  v-on:click.prevent="openIcon(index)"
                  >アイコン選択</b-button
                >
              </div>
              <div class="col-5 col-md-2 btn-upload-icon btn-up">
                <b-button
                  class="float-right"
                  variant="success"
                  v-on:click.prevent="uploadIcon(index)"
                  >アップロード</b-button
                >
                <input
                  type="file"
                  hidden
                  ref="selectFile"
                  id="idSelectFile"
                  accept="image/jpg, image/png, image/jpeg"
                  @change="changeIcon($event, idxBottom, type)"
                />
              </div>
            </div>
            <div
              class="col-12 mx-auto justify-content-center mt-2 setting-slag"
              v-if="value.page_title == '直接URLを入力する'"
            >
              <div class="col-1 col-md-1"></div>
              <div class="setting-show-slag d-flex cus-url mt-2 col-6">
                <p class="cus-slag-label">タイトル</p>
                <div class="custom-select-2 custome-multi-slag cus-url-input">
                  <input
                    v-model="value.title_other"
                    class="form-input m-0 m-0 cus-url-input"
                    type="text"
                  />
                </div>
              </div>
              <div class="setting-show-slag d-flex cus-url mt-2 col-5">
                <p class="cus-slag-label">リダイレクト</p>
                <div class="custom-select-2 custome-multi-slag cus-url-input">
                  <input
                    v-model="value.url"
                    class="form-input m-0 m-0 cus-url-input"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-12 mx-auto justify-content-center mt-2 setting-slag"
            >
              <div class="col-1 col-md-1"></div>
              <div class="d-flex justify-content-center col-6">
                <p class="cus-slag-label">表示設定</p>
                <div class="cus-show-slag">
                  <multiselect
                    v-model="value.slag_display"
                    tag-placeholder="スラグを追加"
                    placeholder=""
                    label="slag_name"
                    track-by="slag_name"
                    :options="listSlagOptions"
                    :multiple="true"
                    :taggable="false"
                    :showNoResults="false"
                    :max-height="200"
                    select-label="選択"
                    selected-label=""
                    deselect-label="削除"
                  >
                    <template v-slot:no-options> リストは空です </template>
                  </multiselect>
                </div>
              </div>
              <div class="d-flex justify-content-center col-5">
                <p class="cus-slag-hd-label">非表示設定</p>
                <div class="cus-show-slag">
                  <multiselect
                    v-model="value.slag_non_display"
                    tag-placeholder="スラグを追加"
                    placeholder=""
                    label="slag_name"
                    track-by="slag_name"
                    :options="listSlagOptions"
                    :multiple="true"
                    :taggable="false"
                    :showNoResults="false"
                    :max-height="200"
                    select-label="選択"
                    selected-label=""
                    deselect-label="削除"
                  >
                    <template v-slot:no-options> リストは空です </template>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center my-4">
            <b-button
              variant="success"
              class="rounded-circle custome-btn-add"
              style="border-radius: 50% !important"
              v-on:click="addBottom()"
              ><b-icon class="sidebar-icon" icon="plus" font-scale="2"
            /></b-button>
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              class="btn-color"
              v-on:click.prevent="updateBottom()"
              :disabled="isLoading"
              variant="success"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              編集内容を反映する
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Add Icon -->
    <b-modal
      id="modal-add-icon"
      class="modal-add-icon"
      size="lg"
      title="アイコン選択"
      hide-footer
      @show="resetModalIcon"
      @hidden="resetModalIcon"
    >
      <div class="list-icon" v-if="listIcons.length">
        <div class="ct-choose" v-for="(value, index) in listIcons" :key="index">
          <b-button
            variant="default"
            v-on:click="chooseIcon(value, index, idxHeader)"
          >
            <b-icon :icon="`${value}`" font-scale="2" />
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- Modal Add Icon -->
  </div>
</template>
<script>
import no_image from "@/assets/img/no-image.jpg";
import icon_home from "@/assets/img/icon_home_bottom.svg";
import icon_content_list from "@/assets/img/icon_content_list_bottom.png";
import icon_shop from "@/assets/img/icon_shop_bottom.png";
import icon_menu from "@/assets/img/icon_menu_bottom.png";
import icon_search from "@/assets/img/icon_search_bottom.png";
import icon_cart from "@/assets/img/icon_cart_bottom.jpg";
import icon_back from "@/assets/img/icon_back_bottom.svg";
import icon_chat from "@/assets/img/icon_chat_bottom.jpg";
import icon_next from "@/assets/img/icon_next_bottom.svg";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { Routes } from "../../utils/routes";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
function initialState() {
  return {
    isLoading: false,
    no_image: no_image,
    active_el: "",
    listIcons: Constants.LIST_ICON,
    listPageOptions: [
      { text: "期間限定ニュース", value: "期間限定ニュース" },
      { text: "ノート確認", value: "ノート確認" },
      // { text: "検索", value: "検索" },
      { text: "お問い合わせ", value: "お問い合わせ" },
      { text: "メンバーページ", value: "メンバーページ" },
      // { text: "ヘルプ", value: "ヘルプ" },
      { text: "ログアウト", value: "ログアウト" },
      { text: "直接URLを入力する", value: "直接URLを入力する" },
    ],
    idxBottom: null,
    listOptions: [
      { text: "ライブラリ", value: 0 },
      { text: "ショップページ", value: 1 },
      { text: "コンテンツ", value: 2 },
      { text: "その他（基本）", value: 3 },
    ],
    listFP: [],
    type: 0,
    listBottom0: [],
    listBottom1: [],
    listBottom2: [],
    listBottom3: [],
    check: false,
    isVisit0: false,
    isVisit1: false,
    isVisit2: false,
    isVisit3: false,
    save: false,
    listDefault0: [
      {
        text: "ホーム",
        value: "ホーム",
        icon: icon_home,
      },
      {
        text: "コンテンツ一覧",
        value: "コンテンツ一覧",
        icon: icon_content_list,
      },
      {
        text: "ショップ",
        value: "ショップ",
        icon: icon_shop,
      },
      {
        text: "メニュー",
        value: "メニュー",
        icon: icon_menu,
      },
      {
        text: "検索",
        value: "検索",
        icon: icon_search,
      },
    ],
    listDefault1: [
      {
        text: "ホーム",
        value: "ホーム",
        icon: icon_home,
      },
      {
        text: "コンテンツ一覧",
        value: "コンテンツ一覧",
        icon: icon_content_list,
      },
      {
        text: "カート",
        value: "カート",
        icon: icon_cart,
      },
      {
        text: "メニュー",
        value: "メニュー",
        icon: icon_menu,
      },
      {
        text: "検索",
        value: "検索",
        icon: icon_search,
      },
    ],
    listDefault2: [
      {
        text: "コンテンツ一覧",
        value: "コンテンツ一覧",
        icon: icon_content_list,
      },
      {
        text: "戻る",
        value: "戻る",
        icon: icon_back,
      },
      {
        text: "ノート/質問",
        value: "ノート/質問",
        icon: icon_chat,
      },
      {
        text: "次へ",
        value: "次へ",
        icon: icon_next,
      },
      {
        text: "目次",
        value: "目次",
        icon: icon_menu,
      },
    ],
    listDefault3: [
      {
        text: "コンテンツ一覧",
        value: "コンテンツ一覧",
        icon: icon_content_list,
      },
      {
        text: "ショップ",
        value: "ショップ",
        icon: icon_shop,
      },
      {
        text: "メニュー",
        value: "メニュー",
        icon: icon_menu,
      },
      {
        text: "検索",
        value: "検索",
        icon: icon_search,
      },
    ],
    listPageOptions0: [],
    listPageOptions1: [],
    listPageOptions2: [],
    listPageOptions3: [],
    listSlagOptions: [],
    shop_id: localStorage.getItem(Constants.SHOP_ID),
  };
}

export default {
  name: "bottomSetting",
  components: {},
  data() {
    return initialState();
  },
  created() {
    const requestFP = {
      shop_id: this.shop_id,
    };
    this.getListFixed(requestFP);
    const request = {
      shop_id: this.shop_id,
      type: this.type,
    };
    this.getBottomSetting(request);
    this.getALLlistSlag(request);
  },
  computed: {
    ...mapGetters([
      "listBottom",
      "listFixedPage",
      "listSlag",
      "message",
      "success",
      "error",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listFixedPage() {
      this.listFP = this.listFixedPage;
      this.listPageOptions = [
        { text: "期間限定ニュース", value: "期間限定ニュース" },
        { text: "ノート確認", value: "ノート確認" },
        { text: "お問い合わせ", value: "お問い合わせ" },
        { text: "メンバーページ", value: "メンバーページ" },
        { text: "ログアウト", value: "ログアウト" },
      ];
      this.listFP.forEach((item) => {
        var fixedPage = {
          text:
            item && item.title && item.title.length > Constants.MAX_LENGTH
              ? item.title.substr(0, Constants.MAX_LENGTH) + "..."
              : item.title,
          value: item.title,
        };
        this.listPageOptions.push(fixedPage);
      });
      this.listPageOptions.push({
        text: "直接URLを入力する",
        value: "直接URLを入力する",
      });
    },
    listBottom() {
      if (this.listBottom && this.listBottom.length == 0 && !this.save) {
        const number = this.type;
        if (this["listBottom" + number].length == 0) {
          this["listBottom" + number].push({
            page_title: "",
            icon: "",
            shop_id: this.shop_id,
            url: "",
            type: "",
            slag_display: [],
            slag_non_display: [],
          });
        }
        this.check = true;
      } else {
        this.listBottom.forEach((bottom) => {
          if (bottom.slag_display && bottom.slag_display.length > 0) {
            bottom.slag_display.forEach((item) => {
              item.slag_name = item.slag_name
                ? item.slag_name
                : item.slag_name_en;
            });
          }
          if (bottom.slag_non_display && bottom.slag_non_display.length > 0) {
            bottom.slag_non_display.forEach((item) => {
              item.slag_name = item.slag_name
                ? item.slag_name
                : item.slag_name_en;
            });
          }
        });
        if (!this["isVisit" + this.type]) {
          this["listBottom" + this.type] = this.listBottom;
          this["isVisit" + this.type] = true;
        } else {
          this["listBottom" + this.type] = this["listBottom" + this.type];
        }
        this.check =
          this["listBottom" + this.type] &&
          this["listBottom" + this.type].length > 0;
      }
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
    },
  },
  methods: {
    ...mapActions({ getListFixed: "getListFixed" }),
    ...mapActions({ getBottomSetting: "getBottomSetting" }),
    ...mapActions({ updateBottom: "updateBottom" }),
    ...mapActions({ getALLlistSlag: "getALLlistSlag" }),
    openIcon(index) {
      this.idxHeader = index;
    },
    chooseIcon(key, index, idxHeader) {
      this.active_el = index;
      this["listBottom" + this.type][idxHeader].icon = key;
      this.$bvModal.hide("modal-add-icon");
    },
    resetModalIcon() {
      this.active_el = "";
    },
    addBottom() {
      this["listBottom" + this.type].push({
        page_title: "",
        title_other: "",
        icon: "",
        shop_id: this.shop_id,
        url: "",
        slag_display: [],
        slag_non_display: [],
      });
    },
    subBottom(index, type) {
      this["listBottom" + type].splice(index, 1);
      if (this["listBottom" + type].length == 0) {
        this["listBottom" + type].push({
          page_title: "",
          title_other: "",
          icon: "",
          shop_id: this.shop_id,
          url: "",
          type: "",
          slag_display: [],
          slag_non_display: [],
        });
      }
    },
    async updateBottom() {
      this.isLoading = true;
      this.save = true;
      let shop_id = this.shop_id;
      var formBottom = new FormData();
      formBottom.append("shop_id", shop_id);
      const listBottomTmp = [];
      if (this.listBottom0.length > 0) this.isVisit0 = true;
      for (var i = 0; i < Constants.AMOUNT_OPTION_BOTTOM; i++) {
        if (!this["isVisit" + i]) {
          const request = {
            shop_id: this.shop_id,
            type: i,
          };
          const data = await this.$store.dispatch("getBottomSetting", request);
          if (data.success) {
            this["listBottom" + i] = this.listBottom;
          }
        }
        const list = this["listBottom" + i];
        if (list.length) {
          for (var j = 0; j < list.length; j++) {
            if (list[j]["page_title"].trim() != "") {
              list[j]["type"] = i;
              list[j]["position"] = j;
            }
            listBottomTmp.push(list[j]);
          }
        }
      }
      for (var ind = 0; ind < listBottomTmp.length; ind++) {
        if (
          listBottomTmp[ind]["page_title"].trim() != "" ||
          listBottomTmp[ind]["icon"].trim() != "" ||
          listBottomTmp[ind]["title_other"].trim() != ""
        ) {
          formBottom.append(
            "data[" + ind + "][page_title]",
            listBottomTmp[ind]["page_title"]
          );
          formBottom.append(
            "data[" + ind + "][title_other]",
            listBottomTmp[ind]["title_other"]
          );
          formBottom.append(
            "data[" + ind + "][icon]",
            listBottomTmp[ind]["icon"]
          );
          formBottom.append(
            "data[" + ind + "][position]",
            listBottomTmp[ind]["position"]
          );
          formBottom.append(
            "data[" + ind + "][page_type]",
            listBottomTmp[ind]["type"]
          );
          if (listBottomTmp[ind]["page_title"].trim() != "") {
            var listBottomUrl = [
              {
                name: "期間限定ニュース",
                url: this.$router.resolve({
                  name: this.$route.params.shopId
                    ? "list new user"
                    : "list new user domain",
                }).href,
              },
              {
                name: "ノート確認",
                url: this.$router.resolve({
                  name: this.$route.params.shopId
                    ? "list note content"
                    : "list note content domain",
                }).href,
              },
              // {
              //   name: "検索",
              //   url: "",
              // },
              {
                name: "お問い合わせ",
                url: this.$router.resolve({
                  name: this.$route.params.shopId
                    ? "create inquiry user"
                    : "create inquiry user domain",
                }).href,
              },
              {
                name: "メンバーページ",
                url: this.$router.resolve({
                  name: this.$route.params.shopId
                    ? "my profile"
                    : "my profile domain",
                }).href,
              },
              {
                name: "ログアウト",
                url: "",
              },
            ];
            this.listFP.forEach((item) => {
              var fixedPage = {
                name: item && item.title,
                url: this.$router.resolve({
                  name: this.$route.params.shopId
                    ? "staticPage"
                    : "staticPage domain",
                  params: { id: item.id },
                }).href,
                id: item && item.id,
              };
              listBottomUrl.push(fixedPage);
            });
            listBottomUrl.forEach((value) => {
              if (value.name == listBottomTmp[ind]["page_title"]) {
                formBottom.append(
                  "data[" + ind + "][url]",
                  value.url.replace(Routes.SHOP + "/" + shop_id + "/", "")
                );
                if (typeof value.id != "undefined") {
                  formBottom.append(
                    "data[" + ind + "][fixed_page_id]",
                    value.id
                  );
                } else {
                  formBottom.append("data[" + ind + "][fixed_page_id]", "");
                }
                return;
              }
            });
            if (listBottomTmp[ind].page_title == "直接URLを入力する") {
              formBottom.append(
                "data[" + ind + "][url]",
                listBottomTmp[ind].url
              );
              formBottom.append("data[" + ind + "][fixed_page_id]", "");
              if (!listBottomTmp[ind].url.match(Constants.REGEX_URL)) {
                this.$toasted.error(
                  "「リダイレクト URL」の形式が正しくありません。"
                );
                this.$store.commit("set", ["message", ""]);
                this.$store.commit("set", ["error", false]);
                this.isLoading = false;
                return;
              }
            }
            if (
              listBottomTmp[ind]["slag_display"] &&
              listBottomTmp[ind]["slag_display"].length > 0
            ) {
              listBottomTmp[ind]["slag_display"].forEach((itemSlag, index) => {
                formBottom.append(
                  "data[" + ind + "][slag_display][" + index + "]",
                  itemSlag.id
                );
              });
            } else {
              formBottom.append("data[" + ind + "][slag_display]", []);
            }
            if (
              listBottomTmp[ind]["slag_non_display"] &&
              listBottomTmp[ind]["slag_non_display"].length > 0
            ) {
              listBottomTmp[ind]["slag_non_display"].forEach(
                (itemSlag, index) => {
                  formBottom.append(
                    "data[" + ind + "][slag_non_display][" + index + "]",
                    itemSlag.id
                  );
                }
              );
            } else {
              formBottom.append("data[" + ind + "][slag_non_display]", []);
            }
          } else {
            formBottom.append("data[" + ind + "][fixed_page_id]", "");
          }
        }
      }
      const dataReturn = await this.$store.dispatch("updateBottom", formBottom);
      if (!dataReturn.success) {
        this.isLoading = false;
      } else {
        this.$router.go(-1);
        Object.assign(this.$data, initialState());
      }
    },
    gotoDashboard() {
      Object.assign(this.$data, initialState());
      this.$router.push({
        name: this.$route.params.shopId ? "menuManager" : "menuManager domain",
      });
    },
    async search() {
      if (!this["isVisit" + this.type]) {
        const request = {
          shop_id: this.shop_id,
          type: this.type,
        };
        const data = await this.$store.dispatch("getBottomSetting", request);
        if (data.success) {
          this["isVisit" + this.type] = true;
        }
      }
    },
    uploadIcon(index) {
      this.idxBottom = index;
      document.getElementById("idSelectFile").click();
    },
    async changeIcon(e, idxBottom, type) {
      if (
        e.target.files[0].type != "image/jpg" &&
        e.target.files[0].type != "image/png" &&
        e.target.files[0].type != "image/jpeg"
      ) {
        this.$toasted.error("画像はjpeg、jpg、png形式である必要があります。");
      } else if (e.target.files[0].size / 1024 / 1024 > 100) {
        this.$toasted.error(
          "アップロード可能なファイルのサイズは100MBまでです。"
        );
      } else {
        var formData = new FormData();
        formData.append("image", e.target.files[0]);
        formData.append("id", this.shop_id);
        const url = `${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            this["listBottom" + type][idxBottom].icon = url;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/.test(url);
    },
    changeType(value) {
      if (value.title_other == "undefined" || value.title_other == "null")
        value.title_other = "";
    },
  },
};
</script>
<style>
.cus-url {
  width: 100%;
}
.cus-url-input {
  width: 100% !important;
}
.cus-row {
  padding-bottom: 20px;
}
.cus-slag-label {
  min-width: 130px;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-right: 0px !important;
}
.cus-slag-hd-label {
  min-width: 130px;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-right: 0px !important;
}
.cus-icon-hd {
  width: 100%;
}
.cus-show-slag {
  width: 100%;
}
</style>
